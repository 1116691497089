import React, { useEffect } from 'react';

import Logo from '~components/auth/Logo';
import Form from '~components/auth/SignIn/Form';
import StyledLogin from './styles';

const SignIn = () => {
  /**
   * Intentional reload to trigger new app version download
   * TODO: perhaps there is a better/more reliable way
   */
  useEffect(() => {
    if (localStorage.getItem('logout') == null) {
      localStorage.setItem('logout', 'true');
      window.location.reload();
    }
  }, []);

  return (
    <StyledLogin
      data-testid="components:auth:signin:signin"
      className="main-login"
    >
      <div className="login-wrap">
        <div className="login-form">
          <Logo />
          <Form />
        </div>
      </div>
    </StyledLogin>
  );
};

export default SignIn;
