// get the theme name current
export const getNameTheme = () => {
  const username = sessionStorage.getItem('global_username') || '';
  const nameKeyTheme = `themeCurrent_${window.APP_NAME}_${username}`;
  return nameKeyTheme;
};

// gets the name of the current topic
export const getThemeCurrent = () => {
  const nameKeyTheme = getNameTheme();
  const themeCurrent = localStorage.getItem(nameKeyTheme) || '';
  return themeCurrent;
};

// validate that the theme exists
export const existTheme = () => {
  const themeCurrent = getThemeCurrent();
  return (
    themeCurrent &&
    sessionStorage.getItem('token') &&
    themeCurrent !== window.APP_NAME
  );
};

// gets the absolute name
export const getNameAbsTheme = (appNameAbsolute) => {
  if (existTheme()) {
    const themeCurrent = getThemeCurrent();
    switch (themeCurrent) {
      case 'blue':
        return 'edgefx';
      case 'blueLight':
        return 'edgefxlight';
      case 'black':
        return 'latamfx';
      default:
        return appNameAbsolute;
    }
  }
  return appNameAbsolute;
};

export const isLightBlue = () =>
  ['blueLight', 'edgefxlight', 'shinhan', 'hanabank', 'banorte'].includes(
    getThemeCurrent() || getNameAbsTheme(window.APP_NAME)
  );

export const setFlexLayoutStyle = (layoutStyle) => {
  if (layoutStyle) {
    const styleFlexLayout = document.getElementById('styleFlexLayout');
    const styHref = `static/vendor/styles/flexlayout/${layoutStyle}.css`;
    if (styleFlexLayout) {
      styleFlexLayout.href = styHref;
    }
  }
};
