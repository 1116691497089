import { httpOrigin } from '~configs/apiOrigin';
import logger from '~utils/logger';

type UserConfigResponse = {
  user: {
    username: string;
    id_companies: string;
    company_name: string;
    id_users: string;
    rate_engine_enabled: boolean;
    shadow_admin: boolean;
    is_slave: boolean;
    is_enable_websocket_routing: boolean;
    permissions: string[];
  };
};

const url = httpOrigin('/trader-rest-service/v2/user-config');

const getUserConfig = async (): Promise<UserConfigResponse | null> => {
  const token = sessionStorage.getItem('token');

  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    return JSON.parse(await resp.text());
  } catch (e) {
    logger.logCriticalError(e);
    return null;
  }
};

export default getUserConfig;
