import { httpOrigin } from '~configs/apiOrigin';
import logger from '~utils/logger';
import { apiVersion } from '~utils/platform';

type UserInformationResponse = string;

const getUserInformation =
  async (): Promise<UserInformationResponse | null> => {
    const apiUrlOrigin = httpOrigin(
      `/trader-rest-service/${apiVersion()}/user-information`
    );

    try {
      const response = await fetch(apiUrlOrigin, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `${sessionStorage.getItem('token')}`,
        },
      });
      const data = await response.json();

      if (!response.ok && data.status !== 200) {
        throw new Error('user information api call failed');
      }

      return data;
    } catch (err) {
      logger.logCriticalError(err);
      return null;
    }
  };

export default getUserInformation;
