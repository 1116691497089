import { httpOrigin } from '~configs/apiOrigin';
import { apiVersion } from './platform';

export const headers = (params?: { [key: string]: string }) => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  Authorization: `${sessionStorage.getItem('token')}`,
  ...(params || {}),
});

export const userSettingsUrl = (domain: string, key: string) =>
  httpOrigin(
    `/trader-rest-service/${apiVersion()}/user-settings?domain=${domain}&key=${key}`
  );
