import { apiVersion } from '~utils/platform';
import { httpOrigin } from '../configs/apiOrigin';
import popoutComponentName from '../utils/popoutComponentName';

const apiUrlOrigin = httpOrigin(`/trader-rest-service/${apiVersion()}/`);

/**
 *
 * @param {Object} data
 * @param {String} data.token - user token
 * @param {Number} data.ping - latency
 * @param {String} data.platform - window platform
 * @returns {Boolean}
 */
const setActiveStatusUser = ({ ping, token, platform }) =>
  new Promise((resolve, reject) => {
    // track in the main window
    if (popoutComponentName()) {
      return;
    }

    fetch(`${apiUrlOrigin}active_user`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({
        ping,
        platform,
        status: true,
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(new Error('error getting active user'));
        }
        return resolve(res.json());
      })
      .catch(() => reject(new Error('error getting active user')));
  });

export default setActiveStatusUser;
