import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { getAuth0Config } from '~utils/platform';

const Auth0ProviderWrapper: React.FC = ({ children }) => {
  const authConfig = getAuth0Config();

  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      useRefreshTokens
      authorizationParams={{
        scope: 'profile email offline_access',
        redirect_uri: authConfig.redirectUri,
        organization: authConfig.organization,
        audience: authConfig.audience,
        // this is a hack to prevent the user from logging in to
        // same user on new tab if a user was already logged in elsewhere
        ...(sessionStorage.getItem('token') ? {} : { prompt: 'login' }),
      }}
      cache={{
        set(key, entry) {
          sessionStorage.setItem(key, JSON.stringify(entry));
        },
        get(key) {
          const json = sessionStorage.getItem(key);

          if (!json) return;

          try {
            const payload = JSON.parse(json);
            // eslint-disable-next-line consistent-return
            return payload;
          } catch (e) {
            // eslint-disable-next-line consistent-return
            return e;
          }
        },
        remove(key) {
          sessionStorage.removeItem(key);
        },
        allKeys() {
          return Object.keys(window.sessionStorage).filter((key) =>
            key.startsWith('@@auth0spajs@@')
          );
        },
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWrapper;
